export const environment = {
  production: true,
  api: {
    baseUrl: 'https://api.insiders.live/asi/api/v1/',
  },
  publicApi: {
    baseUrl: 'https://api.insiders.live/v1/public/',
    baseWsUrl: 'wss://api.insiders.live/v1/public/',
    apiKey: 'Lo7c82Lf1CaZNpZnLw6MtYmw',
  },
  oauth: {
    tokenEndpoint: 'https://api.insiders.live/asi/oauth2/token/',
    issuer: 'https://api.insiders.live/asi/oauth2/token/',
    redirectUri: 'https://platform.insiders.live/',
    clientId: 'asi-race-livestream',
    dummyClientSecret: 'rSKWeVbxLzdc1ZqWa9iAOr4pFmVt4ft7WQWskCpxFAwad1wfG8MKgoI2FmCtbsK0qtceILt5zdzEZmrICBiwBDs5o3bfJK67916rDt0fB1EtzVMStpeTEmkOAkFXlnGv',
    scope: 'all',
    requireHttps: true,
    showDebugInformation: true,
    oidc: false,
    requestAccessToken: true,
    sendAccessToken: true,
    allowedUrls: ['https://api.insiders.live/asi/api/v1/'],
    resourceServer: {
      allowedUrls: ['https://api.insiders.live/asi/api/v1/'],
      sendAccessToken: true,
    },
  },
  sentry: {
    dsn: 'https://24097c981cd34e58bb22951657759942@sentry.vnv.ch/222',
    release: 'insiders-race-livestream-frontend@61830e4404949b6dc53a86c06aca99dfccba55e1',
  },
};
